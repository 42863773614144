<template>
  <van-collapse v-model="activeName" accordion>
    <van-collapse-item v-for="{ name, title, content } in list" :key="name" :title="title">
      <div class="content">{{ content }}</div>
    </van-collapse-item>
  </van-collapse>
</template>

<script>
export default {
  name: 'Help',
  data() {
    return {
      activeName: '1',
      list: [
        { name: '1', title: '1、采购订单进度查询', content: '小程序订单可以通过苗仓采购端-我的订单选择订单后查看目前的进度；也可以通过在线客服查询订单进度' },
        { name: '2', title: '2、修改采购订单', content: '' },
        { name: '3', title: '3、修改配送地址', content: '' },
        { name: '4', title: '4、配送范围', content: '' },
        { name: '5', title: '5、如何申请退款换货', content: '' },
        { name: '6', title: '6、退款时效', content: '' },
        { name: '7', title: '7、小程序订单开票', content: '' },
        { name: '8', title: '8、如何查看发票', content: '' },
        { name: '9', title: '9、增值税专用发票', content: '' },
        { name: '10', title: '10、如何重开发票', content: '' },
        { name: '11', title: '11、支付方式', content: '' },
        { name: '12', title: '12、支付限额', content: '' },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
  ::v-deep .van-collapse-item__content {
    background-color: #f8f8f8;
    color: #333;
    padding: 24px 16px;
  }
</style>
